(function ($) {

  $.fn.accessForm = function () {

    var msg = null;

    var successTimeout = null;

    var modalMsgHide = function (duration) {
      if(!duration) {
        duration = 0;
      }
      $('.modal-msg').fadeOut(duration, function () {
        $('.modal-msg .msg-content').hide();
      });
      msg = null;
    };

    var modalMsgSuccess = function () {
      msg = 'success';
      clearTimeout(successTimeout);
      $('.modal-msg .msg-success').show();
      $('.modal-msg').fadeIn();
      successTimeout = window.setTimeout(modalMsgHide, 3000, 300);
      modalHide();
    };

    var modalHide = function () {
      e = $.Event('hide.bob.modal');
      $('.modal-form-context.modal').trigger(e);
      $('.modal-form-context.modal').fadeOut(400, function() {
        $(this).find('.modal-form-context .m-content').html('');
        var event = $.Event('afterhide.bob.modal');
        $('.modal-form-context.modal').trigger(event);
      });
      $('body').removeClass('modal-bob-open');
    };

    var modalMsgError = function () {
      msg = 'error';
      clearTimeout(successTimeout);
      $('.modal-msg .msg-error').show();
      $('.modal-msg').fadeIn();
    };

    var sendData = function (e) {

      e.preventDefault();

      $('.form-group', '.modal').removeClass('has-error');

      modalMsgHide();

      var data = {};
      var label = {};
      $(':input', e.target).each(function() {
        data[this.name] = $(this).val();
        label[this.name] = $("label[for='"+$(this).attr('id')+"']", '.modal').text();
      });

      var target1 = $('#inputTarget1', '.modal').parent('label').text();
      var target2 = $('#inputTarget2', '.modal').parent('label').text();
      var targetValue = $('input[name=target]:checked', '.modal').val();
      var notValid = false;
      if (targetValue === "1") {
        $('.form-group-target', '.modal').removeClass('has-error');
        data.description += "\n" + target1 + "\n";
      } else if (targetValue === "2") {
        $('.form-group-target', '.modal').removeClass('has-error');
        data.description += "\n" + target2 + "\n";
      } else {
        $('.form-group-target', '.modal').addClass('has-error');
        notValid = true;
      }
      if (data.name.trim() === "") {
        $('.form-group-name', '.modal').addClass('has-error');
        notValid = true;
      } else {
        $('.form-group-name', '.modal').removeClass('has-error');
        data.description += label.name + ": " + data.name + "\n";
      }
      if (data.email.trim() === "") {
        $('.form-group-email', '.modal').addClass('has-error');
        notValid = true;
      } else {
        $('.form-group-email', '.modal').removeClass('has-error');
        data.description += label.email + ": " + data.email + "\n\n";
      }
      if (data.praxis.trim() === "") {
        $('.form-group-praxis', '.modal').addClass('has-error');
        notValid = true;
      } else {
        $('.form-group-praxis', '.modal').removeClass('has-error');
        data.description += label.praxis + ": " + data.praxis + "\n\n";
      }
      if (data.address.trim() === "") {
        $('.form-group-address', '.modal').addClass('has-error');
        notValid = true;
      } else {
        $('.form-group-address', '.modal').removeClass('has-error');
        data.description += label.address + ": " + data.address + "\n\n";
      }
      if (data.betrieb.trim() === "") {
        $('.form-group-betrieb', '.modal').addClass('has-error');
        notValid = true;
      } else {
        $('.form-group-betrieb', '.modal').removeClass('has-error');
        data.description += label.betrieb + ": " + data.betrieb + "\n\n";
      }

      if (notValid) {
        return;
      }

      // disable submit button
      $('#submit', '.modal form.form-access').prop('disabled', true);
      $('document').spinner('show');

      $.ajax({
        url: "/zen",
        type: "POST",
        data: data,
        cache: false,
        success: function (response) {
          modalMsgSuccess();
          
          // tracking by google tagmanager
          if (window.dataLayer !== undefined) {
            if (targetValue === "1") {
              window.dataLayer.push({"event": "conversionSuccessDoc"});
            } else if (targetValue === "2") {
              window.dataLayer.push({"event": "conversionSuccessMfa"});
            }
            window.dataLayer.push({"event": "conversionSuccess"});
          }

          $('document').spinner('hide');
        },
        error: function (xhr, b, x) {
          var responseText = xhr.responseText;
          if(responseText && responseText.indexOf('{"error":"invalid email"}') > -1) {
            $('.form-control.email', '.modal').parent('.form-group').addClass('has-error');
          }
          else if(responseText && responseText.indexOf('{"error":"RecordInvalid"}') > -1) {
            $('.form-control.name', '.modal').parent('.form-group').addClass('has-error');
            $('.form-control.text', '.modal').parent('.form-group').addClass('has-error');
          }
          else {
            modalMsgError();
          }
          // enable button again
          $('#submit', '.modal form.form-access').prop('disabled', false);
          $('document').spinner('hide');
        }
      });

    };

    $('.modal').on('hide.bob.modal', function() {
      if (msg === 'error') {
        modalMsgHide(300);
      }
    });
    $('.modal').on('afterhide.bob.modal', function() {
      // trigger resize event to readjust elements on page (active menu item on mobile, spots...)
      var event = jQuery.Event("resize");
      $(window).trigger(event);
    });

    $(document).off('.accessform');

    $(document).on('submit.accessform', 'form.form-access', function (e) {
      sendData(e);
    });

    $(document).on('click.accessform', '.modal-msg', function () {
      modalMsgHide(300);
    });

    return this;

  };

}(jQuery));
