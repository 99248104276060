(function ($) {

  $(document).ready(function () {

    // **********************

    // check for ios
    if (/ipad|iphone|ipod/i.test(navigator.userAgent)) {
      $('html').addClass('ios');
    }

    // *******************************
    // scroll to link on page (jquery.scrollTo)

    $('body').on('click.scroll', 'a', function (e) {

      var file = $('body').attr('data-file');
      var href = $(this).attr('href');

      // deactivate for carousel
      if (href && href.indexOf('#carousel') === 0) {
        // do nothing
      }
      // href might not be set (e.g. in logofarm)
      else if (href && href.indexOf('#') === 0 && href.length > 1) {
        e.preventDefault();
        $('body').scrollTo(href, 300);
      }
      else if(href && href.indexOf(file) === 0 && href.indexOf('#') > -1) {
        e.preventDefault();
        var link = '#' + href.split('#')[1];
        $('body').scrollTo(link, 300);
      }

    });

    // *******************************
    // passage button

    $('.passage a[title^=button]').each(function(index) {
      var element = $(this);
      var title = element.attr('title');
      if (title.substr(0,6) === 'button') {
        var title_array = title.split('|');
        var cls = $.trim(title_array[1]);
        if (cls) {
          element.attr('class', cls);
        }
        var trg = $.trim(title_array[2]);
        if (trg) {
          element.attr('data-target', trg);
        }
        var aml = $.trim(title_array[3]);
        if (aml) {
          element.attr('data-mailto', aml);
        }
        element.attr('role', 'button');
        element.removeAttr('title');
      }
    });

    // *******************************
    // passage image

    $('.passage img').each(function(index) {
      var element = $(this);
      var title = element.attr('title');
      if (title.substr(0,5) === 'width') {
        var title_array = title.split('=');
        var width = $.trim(title_array[1]);
        if (width) {
          element.css('max-width', width);
          element.removeAttr('title');
        }
      }
    });

    // *******************************
    // footer

    $('.footer span[data-class^=foo]').each(function() {
      var element = $(this);
      var pclass = element.data('class');
      element.parent('p').addClass(pclass);
      element.remove();
    });

    // *******************************
    // fade in page

    $('body').stop().animate({
      'opacity': 0}, 300, function () {
      $('body').css({
        'visibility': 'visible'
      }).animate({
        'opacity': 1
      }, 300);
    });

    // *******************************
    // initialize plugins

    // $('body').anchorhelper();

    $('body').ajaxForm();

    $('body').accessForm();

    $('body').externalForm();

    // $('iframe').lazyLoad();

    // $('.preload').preload();

    $('#bottom').bottomize();

    // $('.jumbotron').jumbotronify({percent: 80});

    // $('.jumbotron').stagify({
    //   delay: 250,
    //   interval: 4000
    // });

    // $('a.popout').popOut();

    $('.modal-show').modal();

    $('.navbar ul').menuhelper();

    $('.navbar-active-item').activenav();

    // $('body').flippify();

    // $('body').simplesliderfy();

    // *******************************
    // fb tracking

    // if (typeof fbq == 'function') {
    //   $(document).on('click', '.btn-access', function () {
    //     fbq('trackCustom', "RequestAccess");
    //   });
    // }


  });

}(jQuery));

