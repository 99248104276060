(function ($) {

  $.fn.externalForm = function() {

    var msg = null;

    var successTimeout = null;

    var modalMsgHide = function(duration) {
      if (!duration) {
        duration = 0;
      }
      $('.modal-msg').fadeOut(duration, function () {
        $('.modal-msg .msg-content').hide();
      });
      msg = null;
    };

    var modalMsgSuccess = function() {
      msg = 'success';
      clearTimeout(successTimeout);
      $('.modal-msg .msg-success').show();
      $('.modal-msg').fadeIn();
      successTimeout = window.setTimeout(modalMsgHide, 3000, 300);
    };

    var modalHide = function() {
      e = $.Event('hide.bob.modal');
      $('.modal-form-context.modal').trigger(e);
      $('.modal-form-context.modal').fadeOut(400, function () {
        $(this).find('.modal-form-context .m-content').html('');
      });
      $('body').removeClass('modal-bob-open');
    };

    var modalMsgError = function(msg) {
      clearTimeout(successTimeout);
      if (msg) {
        // console.log('msg', msg);
        $('.modal-msg .msg-error > span').html(msg);
        $('.modal-msg .msg-error').show();
        $('.modal-msg').fadeIn();
      }
    };


    var modalMsgWarning = function() {
      msg = 'warning';
      clearTimeout(successTimeout);
      $('.modal-msg .msg-warning').show();
      $('.modal-msg').fadeIn();
    };

    var sendData = function(e) {

      e.preventDefault();

      $('.form-group').removeClass('has-error');

      modalMsgHide();

      $('#btn-submit', 'form.externalform').prop('disabled', true);

      var data = {};
      var placeholder = {};
      var label = {};


      $(':input', e.target).each(function() {
        if (this.name) {
          if (this.name.substr(-2) === "[]") { // form fields with [] are arrays
            // get the cc[] values
            if (data[this.name.substr(0, this.name.length - 2)] === undefined) {
              data[this.name.substr(0, this.name.length - 2)] = [];
            }
            data[this.name.substr(0, this.name.length - 2)].push($(this).val().trim());
          } else {
            data[this.name] = $(this).val().trim();
            label[this.name] = $("label[for='"+$(this).attr('id')+"']", '.externalform').text();

          }
        }
      });

      // console.log('data', data);

      var notValid = false;


      function validateEmail(email) {
        var re = "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$";
        var regex = new RegExp(re);
        return regex.test(email);
      }

      if (data.email.trim() === "" || validateEmail(data.email.trim()) === false) {
        $('.form-group-email', 'form.externalform').addClass('has-error');
        notValid = true;
      } else {
        $('.form-group-email', 'form.externalform').removeClass('has-error');
        data.description += label.email + ": " + data.email + "\n\n";
      }

      if (data.name.trim() === "") {
        $('.form-group-name', 'form.externalform').addClass('has-error');
        notValid = true;
      } else {
        $('.form-group-name', 'form.externalform').removeClass('has-error');
        data.description += label.name + ": " + data.name + "\n";
      }
      
      if (data.praxis.trim() === "") {
        $('.form-group-praxis', 'form.externalform').addClass('has-error');
        notValid = true;
      } else {
        $('.form-group-praxis', 'form.externalform').removeClass('has-error');
        data.description += label.praxis + ": " + data.praxis + "\n\n";
      }
      if (data.address.trim() === "") {
        $('.form-group-address', '.modal').addClass('has-error');
        notValid = true;
      } else {
        $('.form-group-address', '.modal').removeClass('has-error');
        data.description += label.address + ": " + data.address + "\n\n";
      }
      if (data.betrieb.trim() === "") {
        $('.form-group-betrieb', 'form.externalform').addClass('has-error');
        notValid = true;
      } else {
        $('.form-group-betrieb', 'form.externalform').removeClass('has-error');
        data.description += label.betrieb + ": " + data.betrieb + "\n\n";
      }

      if (getURLParameter("_") === "m") {
        data.description += ">>> Aufgerufen aus der native mobile App.\n\n";
      } else {
        data.description += ">>> Nicht aus der native mobile App aufgerufen.\n\n";
      }

      if (notValid) {
        // enable button again
        $('#btn-submit', 'form.externalform').prop('disabled', false);
         modalMsgWarning();
        //  console.log(modalMsgWarning());
        return;
      }

      $('document').spinner('show');


      $.ajax({
        url: "/zen",
        type: "POST",
        data: data,
        cache: false,
        success: function (response) {
          // show message
          modalMsgSuccess();

          // tracking by google tag manager
          // google tag manager
          if (window.dataLayer !== undefined) {
            window.dataLayer.push({
              "event": "conversionSuccessNative"
            });
          }

          $('document').spinner('hide');
          // $('form.externalform')[0].reset();
          $('form.externalform').addClass('hidden').trigger("reset");
          $('.success-content').removeClass('hidden');

          var e = $.Event('contentchange.bottomize');
          $(window).trigger(e);
        },
        error: function (err) {
          // console.log(err.status);
          var errmsg = $('form.externalform').data('errmsg');

          modalMsgError(errmsg);
          // enable button again
          $('#btn-submit', 'form.externalform').prop('disabled', false);
          // hide spinner
          $('document').spinner('hide');
        }
      });

    };

    var getURLParameter = function(sParam) {
        var sPageURL = window.location.search.substring(1);
        var sURLVariables = sPageURL.split('&');
        for (var i = 0; i < sURLVariables.length; i++) {
            var sParameterName = sURLVariables[i].split('=');
            if (sParameterName[0] == sParam) {
                return sParameterName[1];
            }
        }
    };

    $('.modal').on('hide.bob.modal', function () {
      if (msg === 'error') {
        modalMsgHide(300);
      }
    });

    $('.modal.modal-confirm').on('hide.bob.modal', function () {
      $('form.externalform').attr('action', '#');
      $('#btn-submit', 'form.externalform').prop('disabled', false);
    });

    $(document).off('.externalform');

    $(document).on('submit.externalform', 'form.externalform', function (e) {
        e.preventDefault();
        sendData(e);
    });

    $(document).on('click.externalform', '.modal-msg', function () {
      modalMsgHide(300);
    });

    return this;

  };

}(jQuery));